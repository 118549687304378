<template>
  <div>
    <BreadBrowseTable
      role="Moderator"
      :model="model"
      :url="`${this.$api.servers.game}/api/v1/zh/moderator/gallery/countByPlayer`"
      :isSearchable="true"
    ></BreadBrowseTable>
  </div>
</template>

<script>
//BOC:[model]
import model from "@/models/items/galleryAnalysis";
//EOC
//BOC:[table]
import BreadBrowseTable from "@/components/Bread/BreadBrowseTableV2";
//EOC
export default {
  components: {
    //BOC:[table]
    BreadBrowseTable,
    //EOC
  },
  data: function () {
    return {
       //BOC:[model]
    model: model,
    //EOC
     
    };
  },
  created(){
   

  },
 mounted(){
 },
  methods: {
   
    
  },
};
</script>
