<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
    <!-- EOC -->
    <!-- BOC:[header] -->
    <v-row class="mb-3">
      <v-col
        ><h1>{{ $t("model.name.themes") }}</h1></v-col
      >
      <v-col class="text-right">
      <v-btn
        color="primary"
        large
        :to="{name:'PageModeratorThemeAdd'}"
      >
        + New Theme
      </v-btn>
    </v-col>
    </v-row>
    <!-- EOC -->


   <!-- BOC:[tabs] -->
   <v-tabs
      v-model="tab"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-tab v-for="item in tabs" :key="item.key" :href="`#${item.key}`">
        {{ item.label }}
      </v-tab>
    </v-tabs>
    <!-- EOC -->
       <!-- BOC:[tab] -->
       <v-tabs-items v-model="tab">
      <!-- BOC:[model] -->
      <v-tab-item key="all" value="all" class="px-1 py-2">
        
  <Year :callbackSelect="selectYear"></Year>
        <!-- BOC:[table] -->
        <BreadBrowseTable
       v-if="!isLoading"
        ref="themes"
          role="Moderator"
          :model="model"
          :url="`${$api.servers.game}/api/v1/en/moderator/gallery/${year}/theme`"
          :isSearchable="true"
        ></BreadBrowseTable>
        <!-- EOC -->
      </v-tab-item>
  <v-tab-item key="analysis" value="analysis" class="px-1 py-2">

<!-- BOC:[table] -->
<TabModeratorGalleryAnalysis
></TabModeratorGalleryAnalysis>
<!-- EOC -->
</v-tab-item>
</v-tabs-items>
    <!-- EOC -->
  </v-container>
</template>

<script>
//BOC:[model]
import model from "@/models/items/theme";
//EOC
//BOC:[table]
import BreadBrowseTable from "@/components/Bread/BreadBrowseTable";
import TabModeratorGalleryAnalysis from "@/tabs/TabModeratorGalleryAnalysis";
//EOC
import { mapState } from "vuex";
import Year from '@/components/Moderator/Theme/Year.vue';
export default {
  components: {
    //BOC:[table]
    BreadBrowseTable,
    TabModeratorGalleryAnalysis,
    Year,
    //EOC
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[tabs]
    tab: null,
    tabs: [
      {
        key: "all",
        label: "All",
      },
      {
        key: "analysis",
        label: "Analysis",
      }
    ],
    //EOC
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[model]
    model: model,
    //EOC
    //BOC:[role]
    role: "Moderator",
    //EOC
    year:null,
    isLoading:true
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("model.name.dashboard"),
      to: { name: "Page" + this.role + "Dashboard" },
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.$t("model.name." + this.model.name.plural.toLowerCase()),
      to: this.$_getRouteBrowse(this.role, this.model.key),
      exact: true,
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    selectYear(year){
      this.year = year
      this.isLoading = true
      var that = this;
      setTimeout(() => {
        that.isLoading = false
      }, 100);
      
    }
  },
};
</script>