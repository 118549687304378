<template>
  <v-select
    label='Year'
    class="white"
    :items="data"
    v-model="key"
    :loading="api.isLoading"
    placeholder="Year"
    outlined
  ></v-select>
</template>

<script>
import moment from 'moment';
export default {
  props: ["callbackSelect", "callbackError",],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    key:null,
    item: null,
  }),
computed: {
      inputVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    },
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
    this.key = moment().year()
    };
    //EOC
  },
  watch: {
    key: function (val) {
      this.key = val;
      this.callbackSelect(this.key);
    },
  },
  methods: {
   fetch() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/en/moderator/data/theme/year";
      this.$api.fetch(this.api);
    },
  },
  mounted(){
    this.fetch()
  }
};
</script>
